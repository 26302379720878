@import '../../../../../../common-style/mixin.scss';
@import '../../../../../../common-style/variable.scss';
@import './index.scss';

.project-create-preview-page.three {
    display: inline-block !important;
    overflow: scroll;

    > .certificate-image {
        @include set-size(100%);
        position: relative;
        
        > img {
            @include set-size(100%);
        }

        // &.placeholder {
        //     opacity: 0;
        // }

        &.printed {
            @include set-absolute($top: 0, $left: 0);
        }        
    }

    $margin: 30px;

    > .certificate-setter {
        @include center-alignment();
        @include set-size(100%, $initialCertificateHeight);
        color: $borderColor;
        position: relative;
        background-color: #fff;

        > img {
            width: 100%;
        }

        > span {
            text-align: center;
        }        
    }

    > p {
        margin-top: $margin !important;
        width: 100%;
        color: #4a4a4a;
        text-align: center;
        word-break: keep-all;
        padding: 0 45px !important;
    }

    > .link {
        @include set-font('ROBOTO', 14px, -0.4px);  
        @include button-action(); 
        
        > span > svg {
            @include set-relative($top: 2px);
            margin-left: 5px;
        }
    }

    > .description {
        @include set-font('ROBOTO', 11px, -0.3px);      
        margin-bottom: $margin !important; 
    }

    > .dpass {
        @include button-action();
        color: #fff;
        background-color: #22b5b1;
        width: 250px;
        border-radius: 100px;        
        margin: 0 auto !important;
        padding: 12px !important;
        
        > * {
            display: inline-block;
            vertical-align: middle;
        }

        > img {
            height: 20px;
            position: relative;
            bottom: 1px;
            margin-right: 5px;
        }
    }

    > *:last-child {
        margin-bottom: $margin + 15px !important; 
    }
}
