@import '../../common-style/variable.scss';
@import '../../common-style/mixin.scss';
@import url('https://fonts.googleapis.com/css?family=Lobster&display=swap');

$verticalPadding: 14px;
$horizontalPadding: 30px;

.header-container {
    
    @include set-font('AndaleMono', 24px, 2px, 300);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: $headerHeight;
    padding: $verticalPadding $horizontalPadding;
    color: #fff;
    position: relative;
    z-index: 1;

    $spanHeight: $headerHeight - ($verticalPadding * 2);

    &.home {
        padding: $verticalPadding calc(#{$horizontalPadding} + (100% - #{$homeWidth}) / 2);
    }

    &.logged-in {
        box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.16), 1px 1px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: $themeBlue;    
    }

    > .front {
        position: relative;

        &.cursor {
            cursor: pointer;
        }

        > img {
            position: relative;
            width: 110px;    
            top: 2px;
        }
    }

    > .middle {
        display: flex;
        align-items: flex-end;
        width: 100%;

        > button:first-child {
            margin-left: 50px;
        }

        > button:not(:last-child) {
            margin-right: 10px;
        }
    }

    > .back {
        display: flex;

        > button:not(:last-child) {
            margin-right: 10px;
        }
    }

    .user {
        @include center-alignment();
        @include  set-font('ROBOTO', 16px, 0);
        @include button-action();
        text-decoration: underline;

        $svgSize: 20px;

        > svg {
            @include set-size($svgSize, $svgSize);
            margin-left: 11px;
        }
    }
}

@media (max-width:500px){
    .header-container{
        font-size:24px !important;
    }
    .parts-button.white{
        padding:5px;
        font-size:12px !important;
        width:80px;
    }
}