@import '../../../common-style/variable.scss';

.carousel.carousel-slider {
    position: relative;
    top:50%;
    width:100vw !important;
    transform: translate(-50%,-50%);  
    .carousel-status{
        display:none;
    }
}

.center {
    .carousel.carousel-slider {
        left: 46%;
    }
}
.bottom {
    .carousel.carousel-slider {
        left: 50%;
    }
}

.center{
    .carousel.carousel-slider {
        .slide{
            position: relative;
            background:none;
            padding:38px 0;
            > div {
                font-size:14px;
                line-height:24px;
                .img-box{
                    width:100%;
                    margin-bottom:30px;
                }
            }
        }
        .control-dots{
            padding:0;
            position: static;
            margin-top:20px;
            > .dot{
                width:6px;
                height:6px;
                border-radius: 50%;
                margin: 0 5px;
                box-shadow: none;
                border:1px solid $themeBlue;
                &.selected{
                    background:$themeBlue
                }
            }
        }
    }
}

.bottom{
    .carousel.carousel-slider {
        margin-top:180px; 
        background:#323e52;
        padding-bottom:90px;
        .slide{
            width:100%;
            height:170px;
            margin:0 auto;
            background:#fff;
            min-width:100%;
            color:#323e52;
            position: relative;
            padding: 64px 40px 0;
            text-align:left;
            line-height: normal;
            p{
              color:#999999;
              font-size:9px;  
              margin-top:22px !important;
            }
            img{
               width:auto;
               position: absolute;
               top:20px;
               left:40px;
            }
        }
        .control-arrow{
            height:170px;
            background:none;
            &.control-next::before{
                border-left: 8px solid #999;
            }
            &.control-prev::before{
                border-right: 8px solid #999;
            }
        }
        .control-dots{
            position: static;
            margin:0;
            padding:0;
            margin-top:24px;
            > .dot{
                width:6px;
                height:6px;
                border-radius: 50%;
                margin: 0 5px;
                box-shadow: none;
                border:1px solid #fff;
                background:none;
                &.selected{
                    background:#fff;
                }
            }
        }
    }
}