@import '../../../common-style/mixin.scss';
@import '../../../common-style/variable.scss';


.broof-container {
	> div {
		@include set-absolute(0, 0, 0, 0);
	}

	> .error {
		@include center-alignment();
		
		> div {
			height: 85px;
			display: block;

			> * {
				display: inline-block;
				vertical-align: middle;
			}

			> img {
				height: 100%;
				margin-right: 12px;
			}

			> div {
				color: $themeBlue;
				
				> p:first-of-type {
					@include set-font('ROBOTO', 20px, 0);
					margin-bottom: 5px !important;
				}

				> p:last-of-type {
					@include set-font('ROBOTO', 12px, 0);
				}
			}
		}
	}
}