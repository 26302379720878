@import '../../../common-style/mixin.scss';
@import '../../../common-style/variable.scss';

.history-table {
    $cellHeight: 40px;
    $svgSize: 18px;

    svg {
        @include set-size($svgSize, $svgSize);
    }

    > table {
        &.head {
            @include set-size(100%, 40px);
        }

        > thead > tr {
            @include set-font('ROBOTO', 14px, -0.4px, normal);
            color: #fff;
            height: $cellHeight;
            background-color: $themeDark;

            > th:nth-of-type(1) {
                width: 25%;
            }

            > th:nth-of-type(2) {
                width: 35%;
            }

            > th:nth-of-type(3),
            > th:nth-of-type(4) {
                width: 20%;
            }
        }
    }

    > .body-wrapper {
        @include set-size(100%, calc(100% - #{$cellHeight}));
        overflow: scroll;

        // TODO
        // 정리
        &::-webkit-scrollbar {
            display: none;
        }    

        // TODO 중복 정리
        > table {
            width: 100%;

            > tbody > tr {
                @include set-font('ROBOTO', 14px, -0.4px, normal);
                color: #323e52;
                height: $cellHeight;
                text-align: center;
                
                &:not(:last-of-type) {
                    > td {                        
                        border-bottom: solid 1px #e1e1e1;
                    }
                }

                &:nth-of-type(2n) {
                    > td {
                        background-color: rgba(0, 8, 255, 0.05);
                    }    
                }

                > td.red {
                    color: #d0021b;
                }

                > td.blue {
                    color: $themeBlue;
                }

                > td:nth-of-type(1) {
                    width: 25%;
                }
    
                > td:nth-of-type(2) {
                    width: 35%;
                }
    
                > td:nth-of-type(3),
                > td:nth-of-type(4) {
                    width: 20%;
                }
            }
        }
    }
}