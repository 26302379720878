@import '../../common-style/mixin.scss';
@import '../../common-style/variable.scss';

.footer{
	@include set-size(100%, $footerHeight);
	display: flex;
	align-items: center;
	justify-content: space-between;

	background: #f2f2f2;
	box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.16);
	padding: $footerPaddingVertical;

	* span{
		@include set-font('ROBOTO', 13px, 0);
		color: #9b9b9b;
	}

	> div:nth-child(1){
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height:100%;

		> div {
			display:flex;
			align-items: flex-end;

			> img{
				height: 20px;
			}
		}

		> span:nth-child(2){
			white-space: pre;
			@include set-font('ROBOTO', 11px, 0);
		}
	}

	> div:nth-child(2){
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items:flex-start;
		height: 100%;

		> span:nth-child(1){
			> a:nth-child(2),
			> a:nth-child(3),
			> a:nth-child(4){
				font-weight:bold;
				text-decoration: underline;
			}
		}

		> span:nth-child(2){
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			width:100%;

			> button{
				border: solid 0.6px #999999;
				border-radius: 18px;
				width: 115px;
				height: 36px;
				font-size: 13px;
				margin-left: 5px;			
			}
		}
	}
}
