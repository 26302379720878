@import '../../common-style/mixin.scss';
@import '../../common-style/variable.scss';
@import '../../common-style/common.scss';

.sign-up-title{
    font-size:28px;
    line-height:40px;
    margin-bottom:48px;
    white-space: pre;
    text-align: center;
    position: absolute;
    color:$themeBlue;
    top:$headerHeight + 80px;
    left:50%;
    transform: translateX(-50%)
}

.block{
    display:block;
    width:100%;
    height:88px;
}

.iconex-connect {
    display: flex;
    align-items: center;
    color: $themeBlue;
    margin-bottom: 42px; // FIXME 이 부분 고쳐야 함

    > .title {
        @include set-size($changeSpanWidth);
        @include set-font('Roboto', 20px, -0.5px);
        padding-right: 40px;
        display: inline-block;
        > .option{
            @include set-font('Roboto', 14px, -0.5px);
        }
    }

    > .address {
        @include set-size($inputSetContentWidth - $changeSpanWidth, 17px);
        @include set-ellipsis();
        @include set-font('Roboto', 16px, -0.4px);        
        display: inline-block;
        margin: 11px 0;
    }

    > button {
        @include set-size($inputSetContentWidth - $changeSpanWidth);
    }
}

.span-division {
    margin: 0 8px;
    color: #e4e4e4;
    
}

.division{
    width:100%;
    margin-bottom:23px;
    border-top: 1px solid #e4e4e4;
}

.link-btn{
    @include set-font('ROBOTO', 12px, -0.3px);
    @include button-action();
    margin-top: 30px;
    text-decoration: underline;
    color: $themeBlue;
}
