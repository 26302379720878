@import '../../../../../../common-style/mixin.scss';
@import '../../../../../../common-style/variable.scss';
@import '../../index.scss';

$sideButtonWidth: 20px;
$displayMargin: 20px;
$previewMargin: 30px;
$topHeight: 40%;
$topMinHeight: 250px;
$borderColor: #e1e1e1;
$initialCertificateHeight: 85%;

$sampleContainerWidth: 368;
$sampleFontSize: 16;
$sampleExtraSize:10;
$sampleExtraWidthRatio: 90;
$sampleWidth: 100;
$sampleMargin: 5;
$sampleTop: 11;

@mixin set-project-create-preview-display($single) {
    display: block;
    position: relative;

    @if $single == true {
        border: none;
        width: 100%;
        margin: 0;
    }
    @else {
        border: solid 1px $borderColor;
        width: calc(100% - #{$sideButtonWidth} * 4 - #{$displayMargin} * 2);
        margin: 0 $displayMargin;
    }
}

.recipient-info {
    @include set-absolute($top: 0, $left: 0);
    font-family: 'ROBOBO';
    z-index: 0;
    color: #000;
    border-radius: 3px;
    text-align: center;
}

.project-create-preview {
    @include set-project-create-half-panel();
    
    > .one, .two, .three {
        @include set-project-create-preview-display(false);

        &::-webkit-scrollbar {
            display: none;
        }    
    }

    &.single {
        width: 100%;

        > .one, .two, .three {
            @include set-project-create-preview-display(true);
        }
    }

    > div {
        @include set-size($sideButtonWidth, 100%);
        @include center-alignment(inline-flex);
        vertical-align: middle;

        $dotSize: 12px;

        &.move {
            > div {
                @include set-size($sideButtonWidth, $sideButtonWidth);
                @include button-action();

                > svg {
                    @include set-size($sideButtonWidth, $sideButtonWidth);
                }
    
            }                                
        }

        &.disabled {                
            > div {
                cursor: default;       
                color: rgba(0,0,0,0.24);
            }
        }

        &.dot {
            > div {
                @include set-size($sideButtonWidth, $sideButtonWidth);
                @include button-action();
                @include center-alignment(inline-flex);

                > svg {
                    @include set-size($dotSize, $dotSize);
                }
            }                                
        }
    }
}   