@import '../../common-style/mixin.scss';
@import '../../common-style/variable.scss';

@mixin set-span-input-p($width) {
    > span {
        width: $width;
    }

    > input {
        width: $inputSetContentWidth - $width;
    }

    > p {
        padding-left: $width !important;
    }
}

.parts-input-set {
    position: relative;
       
    &:not(:last-of-type) {
        margin-bottom: 23px;
    }

    &:last-of-type {
        margin-bottom: 42px;
    }

    &.login {
        @include set-span-input-p($loginSpanWidth)
    }

    &.change {
        @include set-span-input-p($changeSpanWidth)
    }

    > span {
        @include set-font('Roboto', 18px, -0.5px);
        display: inline-block;
        text-align: right;
        padding-right: 40px;
        color: $themeBlue;
        position: relative;
        top: 2px;

        &.error{
            color:$inputBorderRed;
        }
    }

    > input {
        @include set-font('Roboto', 16px, -0.4px);
        height: 55px;
        border: solid 1px $themeBlue;
        border-radius: 3px;
        outline: none;
        background-color: #ffffff00;
        padding: 15px;
        color: #0008FF;

        &.error {
            border-color: $inputBorderRed;
        }
    }

    > p {
        @include set-font('Roboto', 12px, -0.4px);
        color: $inputBorderRed;
        margin-top: 10px !important;
        text-align: left;
        word-break: keep-all;
        
        &.msg{
            color:#0008FF;
        }
    }
    .check {
        position: absolute;
        right: -40px;
        top:16.5px;
        width:23px;
        height:23px;
        border: 1px solid #999999;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        > svg {
            fill: #999999
        }
        
        &.active {
            border:none;
            background:$themeBlue;

            > svg {
                fill: #fff
            }
        }

    }
    .eye {
        @include button-action();
        position: absolute;
        top:16px;
        right:14px;
        color: $themeBlue;
        > svg {
            width:24px;
            height:24px;
        }
    }
}