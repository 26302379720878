@import '../../../common-style/mixin.scss';
@import '../../../common-style/variable.scss';

$cardBorderRadius: 2px;
$cardHeight: 264px;
$bottomHeight: 105px;

.project-card {
    @include set-size(338px, $cardHeight);
    display: inline-block;
    border-radius: $cardBorderRadius;
    margin: 10px;

    $bottomColor: #323e52;
    $moreWidth: 55px;
    $moreMargin: 1px;
    vertical-align: middle;

    &.data {
        // TODO 한번 더 고민
        @include set-relative();
        text-align: left;
        color: #fff;

        $nameH2Height: 65px;
        $lineSpacing: 10px;
        $narrowMargin: 15px;
        $commonMargin: 20px;

        &.PAYMENT_REQUIRED {
            background-color: rgba(255, 255, 255, 1);
            color: $themeBlue;
            
            > .top {
                cursor: pointer;

                > h2 {
                    margin-top: $lineSpacing + 14px !important; // TODO 측정 값
                }
            }

            > .bottom {
                cursor: pointer;

                background-color:rgba(255, 255, 255, 1);
                
                > .progress {
                    background-color: #f2f2f2;
                }

                > .label {
                    border: 1px solid #e4e4e4;
                    color: #bbb;
                }

                > p {
                    font-size: 26px !important;
                    color: #bbb;
                }
            }
        }

        &.STANDBY {
            background-color: rgba(0, 8, 255, 0.35);

            > .bottom > p {
                font-size: 26px !important;
            }

        }

        &.PAUSED {
            background-color: #b00020;

            &.scheduled {
                // FIXME
                // 중복 제거
                > .bottom > p {
                    font-size: 26px !important;
                }                    
            }
        }

        &.IN_PROGRESS {
            background-color: $themeBlue;
        }

        &.FINISHED {
            background-color: rgba(50, 62, 82, 0.2);
            > .bottom {
                background-color:rgba(50, 62, 82, 0);
                > .label {
                    color: rgba(50, 62, 82, 0.4);
                }
            }
        }
 
        &.available {
            > .top > {
                h2 {
                    @include set-size(calc(100% - #{$moreWidth}), $nameH2Height);
                }
            }

            > .bottom {
                > .progress {
                    width: calc(100% - #{$moreWidth});
                }

                > .percentage {
                    right: $moreWidth;
                }

                > p {
                    right: $narrowMargin + $moreWidth;
                }
            }

            > .more {
                @include set-size($moreWidth, calc(100% - #{$moreMargin} * 2));
                background-color: #fafafa;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
                > div > svg {
                    color: $themeBlue;
                }                        
            }
        }

        > .top {
            @include set-relative();
            @include set-size(default, $cardHeight - $bottomHeight);
            display: block;
            padding: $commonMargin;

            > * {
                display: block;            
            }    

            > a {
                @include set-font('ROBOTO', 12px, -0.1px);
                @include button-action();
                color: #ffffff7f;
                text-decoration: underline;
            }
    
            > h2 {
                @include set-font('ROBOTO', 28px, -0.7px, normal);
                @include set-size(default, $nameH2Height);
                margin-top: $lineSpacing !important;
                overflow: hidden;
            }
    
            > p {
                @include set-font('ROBOTO', 14px, -0.1px);
                @include set-absolute($bottom: $lineSpacing, $left: $commonMargin);
            }
        }

        > .bottom {
            @include set-absolute($bottom: 0);
            @include set-size(100%, $bottomHeight);
            display: block;
            background-color: $bottomColor;
            border-bottom-right-radius: $cardBorderRadius;
            border-bottom-left-radius: $cardBorderRadius;
            padding: $commonMargin $narrowMargin 0 $commonMargin;
        
            @mixin set-progress($backgroundColor) {
                @include set-absolute($top: 0, $left: 0);
                @include set-size(100%, 8px);
                display: block;
                background-color: $backgroundColor;
            }

            > .progress {
                @include set-progress(#ffffff66);
                
                > div {
                    @include set-progress(#fff);
                }
            }   
            
            > .label {
                @include set-font('ROBOTO', 14px, -0.5px);
                color: $bottomColor;
                display: inline;
                background-color: #fff;
                padding: 3px 15px;
                border-radius: 50px;
            }

            > .percentage {
                @include set-font('ROBOTO', 14px, -0.1px, lighter);
                @include set-relative($bottom: 5px);
                float: right;
            }

            > p {
                @include set-font('ROBOTO', 36px, -0.3px, lighter);
                @include set-absolute($bottom: $narrowMargin - 5px, $right: $narrowMargin);
            }
        }

        $morePadding: 7px;

        > .more {
            @include set-absolute($top: $moreMargin, $right: $moreMargin);
            @include set-size($moreWidth, calc(100% / 6 + #{$morePadding} * 2 - #{$moreMargin} * 2));
            display: grid;
            align-items: flex-start;
            justify-content: center;
            background-color: #fafafa00;
            border-radius: $cardBorderRadius;
            padding-top: $morePadding;
            padding-bottom: $morePadding;

            > div {
                @include button-action(0.8, 0.4);
                cursor: pointer;
                align-self: center;
                
                &.red > svg {
                    color: #b00020;
                }

                > svg {
                    @include set-size(24px, 24px);
                }    
            }
        }
    }

    &.new {
        @include button-action();
        text-align: center;
        color: $themeBlue;
        border: dotted 1px $themeBlue;    
        cursor: pointer;
        vertical-align: top; // TODO inline-block 에서 역할 확인

        > * {
            @include set-relative($top: 95px);
        }
        
        > svg {
            @include set-size(33px, 33px);
        }
    
        > p {
            @include set-font('ROBOTO', 16px, -0.4px);
            margin: 0;
        } 
    }
}