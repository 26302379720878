@import '../../common-style/mixin.scss';
@import '../../common-style/variable.scss';

// TODO 이건 외부로 빼야 하나
input.div-input {
    color: #9b9b9b;
    border: solid 0.5px rgba(0, 0, 0, 0.1);      
    
    &.error {
        border: solid 0.5px $inputBorderRed !important;
        color: $inputBorderRed !important;
    }

    &::placeholder {
        color: #9b9b9b7f;
    }
}

div.div-input {
    // TODO 정렬 틀어짐 확인
    // @include set-ellipsis();
    // vertical-align: top;
    border: solid 0.5px rgba(0, 0, 0, 0);      
    display: inline-block;
    color: $themeBlue;
    cursor: pointer;

    &.disabled {
        pointer-events: none;
    }
}

$borderRadius: 5px;

.react-datepicker{
    border: 1px solid #e1e1e1 !important;
    border-radius: $borderRadius !important;

    .react-datepicker__navigation--next {
        @include button-action();
        border-left-color: #fff !important;
    }
    .react-datepicker__navigation--previous{
        @include button-action();
        border-right-color: #fff !important;
    }
    .react-datepicker__triangle{
        border-bottom-color: $themeBlue !important;
    }
    
    .react-datepicker__header{
        border-top-left-radius: $borderRadius - 1px;
        border-top-right-radius: $borderRadius - 1px;
        background: $themeBlue !important;
        border-bottom: none;
    }
    .react-datepicker__current-month,
    .react-datepicker__day-name{
        color:#fff !important;
    }
    .react-datepicker__day{
        color: #323e52 !important;
    }    
    .react-datepicker__day--disabled{
        color: #323e5233 !important;
    }    
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected {
        color:#fff !important;
    }    
    .react-datepicker__day--keyboard-selected, 
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__day--selected, 
    .react-datepicker__day--in-selecting-range, 
    .react-datepicker__day--in-range, 
    .react-datepicker__month-text--selected, 
    .react-datepicker__month-text--in-selecting-range, 
    .react-datepicker__month-text--in-range{
        background: $themeBlue !important;
    }
}
