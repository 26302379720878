.coffee_cup{
    width: 20px;
    height: 24px;
    border: 1px #d8d8d8 solid;
    border-radius: 0px 0px 5px 5px;
    position: relative;
}
.coffee_cup:after, .coffee_cup:before{
    position: absolute;
    content: "";
}
.coffee_cup:after{
    width: 5px;
    height: 12px;
    border: 1px #d8d8d8 solid;
    border-left: none;
    border-radius: 0px 20px 20px 0px;
    left: 20px;
}
.coffee_cup:before{
    width: 1px;
    height: 6px;
    background-color: #d8d8d8;
    top: -10px;
    left: 4px;
    box-shadow: 5px 0px 0px 0px #d8d8d8,
                5px -5px 0px 0px #d8d8d8,
                10px 0px 0px 0px #d8d8d8;
    -webkit-animation: steam 1s linear infinite alternate;
       -moz-animation: steam 1s linear infinite alternate;
            animation: steam 1s linear infinite alternate;
}

@-webkit-keyframes steam{
    0%{height: 0px;}
    100%{height: 6px;}            
}
@-moz-keyframes steam{
    0%{height: 0px}
    100%{height: 6px;}            
}
@keyframes steam{
    0%{height: 0px}
    100%{height: 6px;}            
}