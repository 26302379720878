@import '../../../common-style/mixin.scss';
@import '../../../common-style/variable.scss';

.project-list {
    width: calc(100vw - #{$lefterWidth});
    padding: 30px;
    overflow: scroll;

    &.empty {
        text-align: center;

        > h1 {
            @include set-font('ROBOTO', 28px, -0.7px);
            text-align: center;
            color: $themeBlue;            
            padding-top: 100px !important;
            margin: 0 !important;
            margin-bottom: 25px !important;
        }    
    }
}