@import '../../../../../../common-style/mixin.scss';
@import '../../../../../../common-style/variable.scss';
@import './index.scss';

$nameBorderRadius: 3px;

.setter {
    @extend .recipient-info !optional;
    width: px($sampleWidth) !important;
    margin: px($sampleMargin) !important;
    padding: 10px 5px 5px 5px !important;

    > p:nth-child(1) {
        top: px($sampleTop);
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        white-space: nowrap;
        position: absolute;
    }

    &.name {
        font-size: px($sampleFontSize) !important;
        
        > p:nth-child(1) {
            transform: translateX(-50%);
        }

        > p:nth-child(2) {
            margin-top: 30px !important;
        }
    }

    &.extra {
        font-size: px($sampleExtraSize) !important;                
        
        > p:nth-child(1) {
            transform: translateX(-50%) scale(0.8);
            width: px($sampleExtraWidthRatio / 100 * $sampleContainerWidth) !important;
            white-space: pre-wrap;
            word-wrap: break-word;
            word-break: keep-all;
            line-height: 1.3;   
        }

        > p:nth-child(2) {
            margin-top: 22px !important;
        }
    }

    &.drag {
        border: 1px dotted $themeBlue;

        > p:nth-child(2) {
            @include set-font('ROBOTO', 10px, 0);
            // margin-top: 10px !important;
            opacity: 0.5;
            color:#fff;
            background: $themeBlue;
            padding: 3px 0 !important;
            border-radius: $nameBorderRadius;
            cursor: move;
        }

        > p:nth-child(2):active {
            opacity: 0.1;
        }    
    }

    &.not-drag {
        border: none;

        > p:nth-child(2) {
            display: none;
        }    
    }
}