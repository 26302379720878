@import '../../common-style/mixin.scss';
@import '../../common-style/variable.scss';

.parts-table {

    $cellHeight: 40px;
    $svgSize: 18px;

    svg {
        @include set-size($svgSize, $svgSize);
    }

    > table {
        &.head {
            @include set-size(100%, 40px);
        }

        > thead > tr {
            @include set-font('ROBOTO', 14px, -0.4px, normal);
            color: #fff;
            height: $cellHeight;
            background-color: $themeDark;

            > th:nth-of-type(1){
                > div {
                    @include center-alignment();
                    @include button-action();
                }         
            }

            > th:nth-of-type(1),
            > th:nth-of-type(2) {
                width: 5%;
            }

            > th:nth-of-type(3),
            > th:nth-of-type(4),
            > th:nth-of-type(5) {
                width: 22%;
            }

            > th:nth-of-type(6) {
                width: 10%;
            }
        }
    }

    > .body-wrapper {
        @include set-size(100%, calc(100% - #{$cellHeight}));
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }    

        // TODO 중복 정리
        > table {
            width: 100%;

            > tbody > tr {
                @include set-font('ROBOTO', 14px, -0.4px, normal);
                color: #323e52;
                height: $cellHeight;
                text-align: center;
                
                &:not(:last-of-type) {
                    > td {                        
                        border-bottom: solid 1px #e1e1e1;
                    }
                }

                &:nth-of-type(2n) {
                    > td {
                        background-color: rgba(0, 8, 255, 0.05);
                    }    
                }

                // TODO 정리하고 싶다.
                > td:nth-of-type(1),
                > td:nth-of-type(6),
                > td:nth-of-type(7) {
                    > div {
                        @include center-alignment();
                    }         
                }

                > td:nth-of-type(1),
                > td:nth-of-type(6) {      
                    > div {
                        @include button-action();
                    }         
                }

                > td:nth-of-type(1) {
                    svg {
                        color: $tableSvgColor;
                    }  
                }

                > td:nth-of-type(6) {
                    svg {
                        color: $themeBlue;
                    }  
                }

                > td:nth-of-type(7) {                    
                    svg {
                        color: $tableSvgColor;
                    }                          

                    > div > span {
                        @include button-action();
                        height: $svgSize;
                        
                        &:not(:last-of-type) {
                            margin-right: 20px;
                        }
                    }
                }

                > td:nth-of-type(1),
                > td:nth-of-type(2) {
                    width: 5%;
                }
    
                > td:nth-of-type(3),
                > td:nth-of-type(4),
                > td:nth-of-type(5) {
                    width: 22%;
                }

                > td:nth-of-type(6) {
                    width: 10%;
                }

                > td > input {
                    @include set-font('ROBOTO', 14px, -0.4px, normal);   
                    text-align: center;
                    color: #9b9b9b;
                    border-radius: 2px;
                    border: solid 0.5px rgba(0, 0, 0, 0.1);
                    padding: 2px 0;
                }

                > td > input.error {
                    border: solid 0.5px $inputBorderRed;
                }

                > td > input::placeholder {
                    color: #9b9b9b7f;
                }
            }
        }
    }
}