@import '../../common-style/common.scss';
@import '../../common-style/variable.scss';
@import '../../common-style/mixin.scss';

.oops-container {
    @include set-size(100vw, 100vh);
    @include center-alignment();
    background-color: #f2f2f2;
    display: block;

    > div {
        @include set-size($height: 160px);

        > * {
            display: inline-block;
            vertical-align: middle;
        }

        > img {
            height: 100%;
            margin-right: 25px;
        }

        > div {
            height: 100%;
            display: inline-flex;
            align-items: center;

            p {
                @include set-font('ROBOTO', 28px, -0.4px);
                color: $themeBlue;

                &:last-of-type {
                    font-size: 18px !important;
                    margin-top: 10px;
                }
            }
        }
    }
}