@import '../../../../../common-style/mixin.scss';
@import '../../../../../common-style/variable.scss';
@import '../index.scss';

// TODO
// 최상위 css 명 죄다 바꾸기
.project-create-data {
    @include set-project-create-half-panel();
    padding-left: 50px;

    > div {
        @include set-font('ROBOTO', 20px, -0.5px);
        @include center-alignment(inline-flex);
        @include set-size(100%, calc(100% / 6));

        $firstWidth: 125px;

        > *:first-child {
            width: $firstWidth;
        }
        
        > *:nth-child(2) {
            width: calc(100% - #{$firstWidth});
        }

        > input, textarea {
            border-radius: 3px;
            border: solid 1px $themeBlue;
            padding: 15px;
            word-break: keep-all;
        }

        > input::placeholder,
        > textarea::placeholder {
            color: $inputColorLight;
        }

        > input {
            @include set-font('ROBOTO', 20px, -0.5px);
            height: 56px;
            color: $themeBlue;
        }

        > textarea {
            color: $inputColor;
            resize: none;
            height: 78px;
        }

        > textarea.title {
            @include set-font('ROBOTO', 20px, -0.5px);
            color: $themeBlue;
        }

        > textarea.sub-title {
            @include set-font('ROBOTO', 14px, -0.4px);
            color: $themeBlue;
        }

        > .color {
            display: inline-flex;

            $colorSize: 32px;

            > div {
                @include set-size($colorSize, $colorSize);
                @include button-action();
                border: solid 1.5px #f2f2f2;
                border-radius: $colorSize;
                display: block;
                cursor: pointer;

                &:not(:last-of-type) {
                    margin-right: calc((100% - #{$colorSize} * 8) / 7);
                }

                // TODO 리펙토링
                &.react-file-reader {                    
                    > div {
                        @include set-size(100%, 100%);
                        
                        > div {
                            @include set-size(100%, 100%);
                            @include center-alignment();
    
                            $svgSize: 18px;
        
                            > svg {
                                @include set-size($svgSize, $svgSize);
                                color: rgba(0,0,0,0.54);
                            }    
                        }
    
                    }
                }
            }
        }

        > .has-button {
            @include set-font('ROBOTO', 12px, -0.4px);
            text-align: right;

            > span {
                margin-right: 10px;
            }

            // TODO 리펙토링
            > .react-file-reader {
                display: inline-block;
            }
        }
    }
}
