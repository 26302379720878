@import '../../../common-style/mixin.scss';
@import '../../../common-style/variable.scss';

@mixin inner-div($width, $topBorderSize, $marginRight) {
    margin-right: calc(#{$width} - #{$topBorderSize} + #{$marginRight});
    
    > div {
        @include set-size($width);
    }
}

$sidePosition: calc((100% - #{$homeWidth}) / 2);
$left: 122px;

.mobile {
    display: none;
}

.home-container {
    * {
        word-break: keep-all;
    }

    > .box-container {
        @include set-absolute($top: 0, $left: 0);
        @include set-size(100vw, 100%);

        > .box {
            @include set-size($homeWidth);
            background-color: $themeBlue;
    
            &.left {
                @include set-size(700px, 731px);
                @include set-absolute($top: 0, $left: calc(#{$sidePosition} - 40px));
                transform: rotate(10deg);
            }
    
            &.right {
                @include set-size(1005px, 690px);
                @include set-absolute($top: 50px, $right: calc(#{$sidePosition} - 55px));
                transform: rotate(-13deg);          
            }
        }
    }

    > .top-back-container {
        @include set-absolute($top: 0, $left: 0);
        @include set-size(100vw, 800px);
        background-color: $themeBlue;

        > img {
            width: 1440px;
            margin: 0 auto;
            display: block;
            opacity: 0.15;
        }
    }

    > .content {
        @include set-absolute($top: 0, $left: $sidePosition);
        @include set-size($width: $homeWidth);
        @include center-alignment();

        $top: 195px;
        $marginTop: 44px;

        > div {
            > .left {
                @include set-absolute($top: $top, $left: $left + 50px);
                
                > div {
                    @include set-font('TimesNewRoman', 85px, normal, lighter);
                    line-height: 1.2;
                    color: #fff;        
                }
            
                > button {
                    margin-top: $marginTop - 20px;
                    font-size: 18px !important;
                }   
            }
    
            > .right {
                @include set-absolute($top: $top + 20px, $right: 166px);
                @include set-size($width: 440px);
    
                > .description {
                    @include set-font('ROBOTO', 40px, -0.4px, lighter);
                    color: #fff;
                }
    
                > .list {
                    @include set-font('ROBOTO', 18px, -0.4px, lighter);
                    margin-top: $marginTop;
                    text-decoration: underline;
                    line-height: 2;
                    color: #fff;
                    opacity: 0.6;

                    & > div:first-child {
                        @include set-fade-in-out(2s);
                    }
                }
            }
        }       
    }

    > .flow {
        @include set-absolute($top: 930px, $left: $sidePosition);
        @include set-size($width: $homeWidth);
        @include center-alignment();
        
        $topBorderSize: 15px;
        $firstdWidth: 405px;
        $secondWidth: 239px;
        $thirdWidth: 151px;
        $fourthWidth: 204px;
    
        $firstMargin: 54px;
        $secondMargin: 57px;
        $thirdMargin: 58px;

        $flowLineTopPosition: 1230px;

        &.back-line {
            @include set-size(100vw, 65px);
            @include set-absolute($top: $flowLineTopPosition, $left: 0);    
            background-color: $themeBlue;
            z-index: 0;
        }

        &.bottom-image {
            @include set-absolute($top: $flowLineTopPosition - 59px, $left: 0); // TODO 측정값
            @include set-size(100vw);
            @include center-alignment();
        }

        > div {            
            @include set-font('ROBOTO', 16px, -0.4px);

            > div {
                z-index: 1;
                display: flex;
                color: $themeBlue;
            }
            
            > .top {
                margin-bottom: 100px;

                > div:nth-child(1) {
                    @include set-size($firstdWidth);
                    @include set-font('ROBOTO', 40px, -0.4px);
                    margin-right: $firstMargin;
                }
        
                > div:nth-child(2),
                > div:nth-child(3),
                > div:nth-child(4){
                    @include set-size($topBorderSize);
                    border-top: solid 1px $themeBlue;
                    padding-top: 20px;
                }
        
                > div:nth-child(2) {
                    @include inner-div($secondWidth, $topBorderSize, $secondMargin);
                }
        
                > div:nth-child(3) {
                    @include inner-div($thirdWidth, $topBorderSize, $thirdMargin);
                }
        
                > div:nth-child(4) {
                    @include inner-div($fourthWidth, $topBorderSize, 0);
                }    
            }
            
            > .center {
                > div:nth-child(1) {
                    @include set-size($firstdWidth);
                    margin-right: $firstMargin;
                    padding-left: 70px;
                }
        
                > div:nth-child(2) {
                    @include set-size($secondWidth);
                    margin-right: $secondMargin;
                }
        
                > div:nth-child(3) {
                    @include set-size($thirdWidth);
                    margin-right: $thirdMargin;
                }
        
                > div:nth-child(4) {
                    @include set-size($fourthWidth);
                }    
            }
        }
    }

    > .praise {
        @include set-absolute($top: 1550px);
        width: 100%;
        color: $themeBlue;
        display: flex;
        justify-content: center;
        
        > div {
            @include set-size(550px);

            &:first-of-type {
                padding-left: 20px;
                margin-right: 100px;
            }

            $svgSize: 50px;
            > svg {
                @include set-size($svgSize, $svgSize);
                right: 25px;
                bottom: 10px;
                position: relative;
            }

            > div:first-of-type {
                @include set-font('ROBOTO', 40px, -0.4px);
            }

            > div:nth-of-type(2) {
                @include set-font('ROBOTO', 18px, -0.4px);
                margin-top: 20px;
            }
        }
    }

    $partnerHeight: 330px;

    > .partner {
        @include set-absolute($top: 1930px);
        @include set-size(100%, $partnerHeight);
        display: flex;
        justify-content: center;
        
        > div {
            @include set-size(1225px, $partnerHeight);
            @include center-alignment();
            background-color: #323e52;     
            > img {
                width: 1049px;
            }           
        }
    }

    > .footer {
        @include set-font('ROBOTO', 14px, 0);
        @include set-absolute($top: 2230px + 240px);
        @include set-size(100%, 155px);
        // padding: 0 calc(#{$sidePosition} + #{$left});
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $themeBlue;
        // padding-left: 30px;
        // padding-right: 30px;
        > span {
            &:nth-child(1){
                margin-left:0;
            }
            &:nth-child(3){
                margin-right:0;
            }
        }

        > span > a {
            @include button-action();
            text-decoration: underline;
        }
    }
}

@media (max-width:500px){
    body::-webkit-scrollbar , html::-webkit-scrollbar , #root::-webkit-scrollbar {
        display:none;
    }
    .mobile{
        display:block;
    }
    #root{
        width:100vw;
        overflow-x: hidden;
    }
    .header-container.home{
        padding:10px 20px !important;
        position: static;
        background:$themeBlue;
    }
    .home-container{
        top:0;
        overflow-y:hidden;
        background:url('../../../images/bg-color.png') no-repeat;
        background-size: 100vw 540px;
        &::-webkit-scrollbar { 
            display: none; 
        }

        .top-btn{
            position: fixed;
            bottom:20px;
            right:20px;
            background:$themeBlue;
            width:42px;
            height:42px;
            text-align: center;
            line-height:52px;
            border-radius: 50%;
            z-index:999;
            > svg{
                color:#fff;
                width:20px;
                height:20px;
            }
        }
        > .box-container{ 
            display:none;
        }
        > .content{
            width:320px;
            margin:0 auto;
            position: relative;
            top:0;
            left:0;
            padding:84px 30px 0 30px;
            > div {
                position: relative;
                width:100%;
                > .right{
                    display:none;
                }
                > .left{
                    position: relative;
                    top:0;
                    left:0;
                    width:183px;
                    > div{
                        padding-top:10px;
                        margin-bottom:100px;
                        font-size: 46px !important;
                    }
                    > button{
                        display:none;
                    }
                }
            }
        }
        > .flow{
            width:320px;
            margin:0 auto;
            position: static;
            display:block !important;
            padding:130px 0;
            color:#000;
            > div {
                width:100%;
                padding: 0 20px;

                > div{
                    display:block;
                    padding:0 30px;
                    &.top{
                        position:relative;
                        margin-top:40px;
                        margin-bottom:160px;
                        &::before{
                            content:'';
                            display:block;
                            background:$themeBlue;
                            width:1px;
                            height:160px;
                            position: absolute;
                            top:150px;
                            left:55px;
                            z-index:-1
                        }
                        > div:nth-child(1){
                            width:100%;
                            display:block;
                            margin-right:0;
                            font-size:18px !important;
                            line-height:1.5;
                            text-align:center;
                            margin-bottom:48px;
                        }
                        > div:nth-child(2),
                        > div:nth-child(3),
                        > div:nth-child(4){
                            border-top:none;
                            padding-top:0;
                            width:52px;
                            height:52px;
                            line-height:20px;
                            margin-bottom:33px;
                            font-size:14px;
                            background-size:cover;
                            >div{
                                padding:6px 0 0 68px;
                                width: 250px;
                            }
                        }
                        > div:nth-child(2){
                            background: url('../../../images/flow1.png') no-repeat;
                            background-size:cover;
                        }
                        > div:nth-child(3){
                            background: url('../../../images/flow2.png') no-repeat;
                            background-size:cover;
                        }
                        > div:nth-child(4){
                            background: url('../../../images/flow3.png') no-repeat;
                            background-size:cover;
                        }
                    }
                    &.center{
                        position: relative;
                        > .center-title {
                            text-align:center;
                            font-size:18px;
                        }
                        > div{
                            position: static;
                        }
                    }

                }
            }
        }
        > .bottom{
            position: relative;
            width:100vw;
            height:550px;
            left:50%;
            transform: translateX(-50%);
            $top: 210px;
            $marginTop: 44px;
            padding:40px 50px 0;
            color:#fff;
            text-align:center;
            background:#323e52;
            > .description {
                @include set-font('ROBOTO', 18px, -0.4px, lighter);
                > span{
                    @include set-font('ROBOTO', 14px, -0.4px, lighter);
                }
            }
    
            > .partner_mobile img{
                width:90%;
                margin:0 auto;
                margin: 32px 0 22px;
                opacity:0.6;
            }

            > div:nth-child(3){
                max-height:0;
            }
        }
        > .partner{
            position: static;
        }
    }


    .service{
        color:#fff;
        text-align:center;
        line-height:24px;
        font-size:14px;
        svg{
            margin-top:10px;
            fill: #fff;
            display:block;
            width:30px;
            height:30px;
            margin-left:calc(50% - 15px)
        }
    }
    .mobile-footer{
        position: absolute;
        left:0;
        bottom:0;
        width:100%;
        font-size:11px;
        color:#777;
        line-height:30px;
        padding:  0 20px;
        border-top:solid 0.5px rgba(255, 255, 255, 0.1);
        span {
            float:left;
        }
        a{
            float:right;
        }
    }

    .home-container > .flow > div > .center > div:nth-child(2){
        position: relative;
        top:200px;
    }
}
