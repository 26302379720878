@import '../../../../common-style/mixin.scss';
@import '../../../../common-style/variable.scss';
@import './index.scss';

$padding: 45px;

.issue-popup {
    @include set-size($height: 570px);
    display: flex;
    position: relative;
    background-color: #fff;
    z-index: 0;

    > .project-create-preview {
        width: 370px;
    }

    > .right-content {
        border-left: 1px solid #e1e1e1;
        position: relative;
        display: block;
        padding: $padding;
        color: $themeBlue;

        > .count {
            @include set-font('ROBOTO', 24px, -0.5px);
        }

        > .question {
            @extend .count;
            margin: 30px 0 57px 0;
        }

        > .title {
            font-weight: 700;
        }

        input {
            @include set-common-div-input(135px, 40px); // TODO 측정 값
            @include set-font('ROBOTO', 16px, -0.4px);
            text-align: center;
            border: 1px solid $themeBlue;
            color: $themeBlue;
            border-radius: 5px;
        }

        > .period {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0 45px 0;

            &.error {
                margin-bottom: 0!important;
            }

            > .dash {
                @include set-common-top-span();
                margin: 0 10px;    
            }
        }
        
        > .period-error {
            @include set-font('Roboto', 12px, -0.4px);
            color: $inputBorderRed;
            margin-top: 10px !important;
            margin-bottom: 20px !important;
            text-align: left;    
        }

        > .link {
            span {
                margin-right: 5px;
            }

            input {
                width: 153px;
                text-align: left;
                padding: 0 5px;
            }
        }

        > .query-error {
            @include set-font('Roboto', 12px, -0.4px);
            color: $inputBorderRed;
            margin-top: 10px !important;
            text-align: left;    
        }

        $bottomWidth: calc(100% - #{$padding} * 2) !important;

        > button {
            @include set-absolute($bottom: 80px);
            width: $bottomWidth;
        }

        > .causion {
            @include set-font('ROBOTO', 12px, -0.4px);
            @include set-absolute($bottom: 50px);
            width: $bottomWidth;
            text-align: center;
        }
    }
}

.issue-confirm-link {
    @include set-font('ROBOTO', 20px, -0.5px);
    display: flex;
    justify-content: space-between;
    color: $themeBlue;
    padding: 15px 20px;
    border: solid 1px $themeBlue;
    border-radius: 5px;

    > span > svg {
        @include button-action();
    }
}
