@import '../../../common-style/variable.scss';
@import '../../../common-style/mixin.scss';

$areaMargin: 20px;
$itemPadding: 18px;
$width: 350px;

.toast-area {
    position: absolute;
    top: $headerHeight;
    right: 0;
    width: $width;
    padding: $areaMargin;
    display: block;
    overflow: hidden;

    > .toast-item {
        width: 100%;
        white-space: pre-wrap;
        word-break: keep-all;
        position: relative;
        color: white;
        font-weight: 400;
        padding: $itemPadding;
        padding-right: $itemPadding * 2 + 16px; // MdClose 16px
        display: flex;
        justify-content: space-between;
        background-color: #323e52;
        box-shadow: 3px 3px 9px 0 rgba(0, 0, 0, 0.3);
        font-size: 15px;
        // right: 0 - $width - $areaMargin;
        animation-name: bounceInRight;
        animation-duration: 0.5s;
        animation-duration: ease;

        &.error {
            background-color: #ff0066;
        }

        &.out {
            animation-name: bounceOutRight;
            animation-duration: 0.5s;
            animation-duration: ease;
            animation-fill-mode: forwards;
        }

        > div.undo {
            display: inline-table; // 모른 채 되는 거 찾음
            @include button-action();
            color: #4a90e2;
            min-width: 55px;
            text-align: center;
            font-size: 13px;
            margin-left: $itemPadding;
            margin-top: 1px; // 위치 살짝 조정
        }
        
        > .toast-close {
            @include button-action();
            position: absolute;
            top: $itemPadding;
            right: $itemPadding;

            > svg {
                color: white;
            }    
        }

        &:not(:first-child) {
            margin-top: $areaMargin;
        }
    }
}

@mixin timing-function {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

@keyframes bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        @include timing-function;
    }
    from {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        transform: translate3d(10px, 0, 0);
    }
    90% {
        transform: translate3d(-5px, 0, 0);
    }
    to {
        transform: none;
    }
}

@keyframes bounceOutRight {
    35% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}