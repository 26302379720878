@import '../../common-style/mixin.scss';
@import '../../common-style/variable.scss';
@import '../../common-style/common.scss';


.privacy{
    * {
        word-break: keep-all;
    }

    display:block;
    text-align: left;
    width:375px;
    padding-left:150px;
    margin-bottom:40px;
    &.issued{
        margin-bottom:0 !important;
        padding-left:0;
        width:100%;
        margin:25px 0 0;
        .title{
            color:#9b9b9b;
        }
        .check.active{
            background:none;
            svg {
                position: relative;
                right: 1px;            
            }
        }
        .privacy-toggle table{
            width:100%;
        }
        .privacy-toggle .bottom-txt{
            font-size:8px;
        }
        .check {
            svg {
                position: relative;
                right: 1px;            
            }
        }
    }
    .check{
        border:1px solid #999999;
        border-radius: 50%;
        width:23px;
        height:23px;
        float:left;
        margin-right:9px;
        text-align:center;
        line-height:25px;
        font-size: 16px;

        &.active{
            background:$themeBlue;
            border:1px solid #ffffff00;
            svg{
                fill: #fff;
            }
        }

        cursor: pointer;
        svg{
            fill: #9b9b9b;
        }
    }
    .title{
        color:$themeBlue;
        font-size:12px;
        margin-bottom:16px;
        line-height:23px;
        cursor: pointer;
        white-space: nowrap;

        svg {
            position: relative;
            top: 2px;
            margin-left: 3px;
        }
    }
    .privacy-toggle{
        display:none;
        &.active{
            display: block;
            margin-bottom:40px;
        }
        margin-top:16px;
        color:#9b9b9b;
        .top-txt{
            font-size:11px;
        }
        table{
            margin:8px 0;
            width:335px;
            text-align:center;
            font-size:10px;
            color:#9b9b9b;
            table-layout: fixed;
            thead{
                background:#f2f2f2;
                line-height:22px;
            }
            tbody{
                line-height:26px;
                th{
                    white-space: pre-wrap;
                    line-height:15px;
                    padding:10px 5px;
                    &:last-of-type {
                        text-decoration: underline;
                    }
                }
            }
        }
        .bottom-txt{
            font-size:11px;
            line-height:18px;
            white-space: pre-wrap;
            &.mb{
                margin-bottom:16px !important;
            }
        }
    }
    .error{
        color:#d0021b;
        font-size:12px;
        line-height:16px;
        white-space: nowrap;
    }
}
