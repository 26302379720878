@import './mixin.scss';
@import './variable.scss';

.no-menu-container {
    display: block;
    min-height: calc(100vh - #{$headerHeight} - #{$footerHeight}); // TODO 이거 화면 줄이면 이상해짐 100% 하면 전체 높이로 안나옴
    background-color: $contentBackgroundColor;
    padding: 140px calc((100% - #{$inputSetContentWidth}) / 2) ;
    text-align: center;

    > p {
        @include set-font('ROBOTO', 12px, -0.4px);
        color: $inputBorderRed;
        margin-top: 10px !important;
        text-align: center;
    }
}

.has-menu-container {
    display: flex;
    height: calc(100vh - #{$headerHeight});
    width: 100vw;
    background-color: $contentBackgroundColor;
}