@import '../../../common-style/mixin.scss';
@import '../../../common-style/variable.scss';

@mixin set-common-div-input($width, $height) {
    @include set-font('ROBOTO', 18px, -0.4px, normal);
    @include set-size($width, $height);                
    line-height: $height - 2px; // TODO 측정값
    border-radius: 2px;
    padding: 1px 0 0 0;
}

@mixin set-tab-color($color) {
    border-bottom: solid 2px $color;
    color: $color;    
}

@mixin set-template-people() {
    @include set-size(100%, 650px);
    background-color: #fff;
    border-radius: 2px;
}

.information-history {
    @include set-content-size();
    overflow: scroll;

    $contentsMargin: 40px;

    > .contents {
        @include set-size(1140px, 815px);
        position: relative;
        padding: 0 $contentsMargin;
        margin: $contentsMargin auto;

        $tabWidth: 180px;
        $tabMargin: 4px;
        $divInputHeight: 28px;

        > .top {    
            > div.name {
                @include set-common-div-input(($tabWidth * 2 + $tabMargin) * 3 / 2, $divInputHeight);
            }

            > .right {
                float: right;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                
                > .id {
                    margin-right: 20px;
                }
            }
        }

        // TODO .project-create 내 .tab 과 같고 $tabWidth 만 다름
        > .tab {
            @include set-size(100%, 52px); // TODO 측정 값
            margin-top: 30px;

            > div {
                @include set-font('ROBOTO', 15px, -0.4px);
                @include center-alignment(inline-flex);
                @include set-size($tabWidth, 100%);
                @include set-tab-color($color: rgba(0,0,0,0.54));
                cursor: pointer;

                $svgSize: 19px;

                > svg {
                    @include set-size($svgSize, $svgSize);
                    position: relative;
                    margin-right: 8px;
                    bottom: 1px;
                }

                &.selected {
                    @include set-tab-color($color: $themeBlue);
                }

                &:not(:last-of-type) {
                    margin-right: $tabMargin;
                }
            }
        }

        // TODO .project-create 내 .templete 과 같음
        > .board {
            @include set-template-people();
            padding: 30px 30px 40px 30px;
            display: block;

            // TODO 이것 좀 깔끔히 안될까?
            $topMargin: 10px;
            $bottomMargin: 30px;
            $sideMargin: 30px;
            $topHeight: 40px;
            $bottomHeight: 15px;
            $lineColor: rgba(0, 0, 0, 0.1);               
            $tableBoxWidth: 100%;
            $tableBoxHeight: calc(100% - #{$bottomHeight});

            > .table-box {
                @include set-size($tableBoxWidth, $tableBoxHeight);
                border-bottom: solid 1px $lineColor;
            }
        
            > .bottom {
                @include set-size(100%, $bottomHeight);
                margin-top: $topMargin;
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                color: $themeBlue;
        
                > .count {
                    @include set-font('ROBOTO', 14px, -0.4px, normal);
                }
            }
        }
    }
}