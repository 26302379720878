@import '../../../common-style/mixin.scss';
@import '../../../common-style/variable.scss';
@import '../../../common-style/common.scss';

// TODO no-manu-container 와 다른가?

$marginTop: 75px;

.containers-payment {
    // min-height: calc(100vh - #{$headerHeight} - #{$footerHeight} - #{$marginTop});
    min-height: calc(100vh - #{$headerHeight} - #{$footerHeight} - #{$marginTop} * 2);
    display: block;

    > .title {
        @include set-font('ROBOTO', 28px, -0.7px);
        color: $themeBlue;
        width: 100%;
        margin-top: $marginTop;
        text-align: center;
    }

    > .description {
        @include set-size(100%, 190px);
        @include center-alignment();
        margin-top: 20px;
        
        > * {
            width: 360px;
        }

        > img {
            margin: 0 35px;
        }

        > div {
            @include set-font('ROBOTO', 18px, -0.4px);   
            padding-top: 40px; // TODO 측정 값
            color: $themeBlue;
            line-height: 2.5;

            &:first-of-type {
                text-align: right;
            }
        }
    }

    > .space {
        height: 50px;
        margin-top: 20px;
    }

    > .change {
        @extend .space;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        
        > div {
            width: $paymentTicketWidth * 3 + $paymentTicketPadding * 2;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            align-items: center;
    
            > span {
                @include set-font('ROBOTO', 14px, -0.3px, bold);   
                color: rgba(0,0,0,0.87);
                background-color: #f8d61c;
                margin-right: 10px;
                padding: 3px 8px;
            }
        }
    }

    > .tickets {
        @include center-alignment();
        margin-top: 10px;

        > *:nth-child(2) {
            margin: 0 $paymentTicketPadding;
        }
    }

    > .icx-price {
        @include set-font('ROBOTO', 14px, -0.4px);       
        width: $paymentTicketWidth * 3 + $paymentTicketPadding * 2;
        margin: 13px auto;
        text-align: right;
        color: $themeBlue;        

        > span {
            font-size: 11px;
        }
    }

    
}

@media screen and (max-height: 1160px) {
    .containers-payment  {
      margin-bottom:75px;
    }
  }

  @media screen and (min-height: 1100px) {
    .containers-payment  {
        min-height: calc(100vh - #{$headerHeight} - #{$footerHeight} - #{$marginTop});
    }
  }