$headerHeight: 65px;
$footerHeight: 155px;
$footerPaddingVertical: $footerHeight * 20/100;
$lefterWidth: 300px;

$themeBlue: #0008ff;
$themeDark: #323e52; // TODO 전체 검색
$inputBorderRed: #d0021b;
$contentBackgroundColor: #f2f2f27f;

$inputSetContentWidth: 375px;
$loginSpanWidth: 110px;
$changeSpanWidth: 150px;

$tableSvgColor: rgba(0,0,0,0.54);

$paymentTicketWidth: 390px;
$paymentTicketPadding: 20px;

$homeWidth: 1440px;
