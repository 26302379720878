@import '../../../../../../common-style/mixin.scss';
@import '../../../../../../common-style/variable.scss';
@import './index.scss';

.project-create-preview-page.two {
    display: inline-block !important;
    position: relative;

    > .background {
        @include set-absolute(0, 0, 0, 0);       
        @include set-size(100%, 100%);
        display: block;
    }

    > .half {
        @include set-size(100%,  50%);
        color: #fff;
    }

    > .top {
        @include set-font('ROBOTO', 22px, -1.4px);
        @include center-alignment();
        @include set-fade-in-out(2s);
        text-align: center;
        padding: 0 75px;
        word-break: keep-all;
    }

    > .bottom {        
        $sideMargin: 40px;
        $detailHeight: 200px;
        $detailWidth: calc(100% - #{$sideMargin} * 2);

        > .detail {
            @include set-absolute($left: 40px, $bottom: 60px);
            @include set-size($detailWidth, $detailHeight);
            display: inline-block;
            border-radius: 8px;
            background-color: rgba(255, 255, 255, 0.15);
            text-align: left;

            $verticalPadding: 15px;
            $horizontalPadding: 20px;
            $bottomPadding: $verticalPadding $horizontalPadding;

            $titleHeight: 50px;

            > .title {
                @include set-font('ROBOTO', 16px, -0.4px);
                padding: $bottomPadding;
                height: 50px;
            }

            > table {
                @include set-font('ROBOTO', 14px, -0.4px);
                @include set-size(100%);
                position: relative;
                padding: $bottomPadding;
                padding-top: 5px;

                $firstWidth: 50px;

                tr {
                    &:not(:last-child) {
                        height: calc((#{$detailHeight} - #{$titleHeight}) / 4);
                    }

                    &:last-child {
                        height: calc((#{$detailHeight} - #{$titleHeight}) / 4 * 2);
                    }
                }

                td {
                    display: inline-block;
                    vertical-align: top;
                }

                td:first-of-type {
                    width: $firstWidth;;
                    font-weight: bold;
                }

                td:last-of-type {
                    word-break: keep-all;
                    width: calc(100% - #{$firstWidth});
                }
            }
        }        
    }
}