@import '../../../common-style/mixin.scss';
@import '../../../common-style/variable.scss';

$borderRadius: 4px;
$topHeaderHeight: 55px;
$paymentTicketHeight: 280px;
$horizontalPadding: 50px;
$verticalPadding: 30px;

.payment-ticket {
    @include set-size($paymentTicketWidth, $paymentTicketHeight);
    
    > .top-header {
        @include set-size(100%, $topHeaderHeight);
        @include set-font('ROBOTO', 20px, -0.5px);
        display: flex;
        align-items: center;
        padding-left: 30px;
        color: #fff;
        background-color: #323e52;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;

        &.highlight {
            background-color: $themeBlue;
        }
    }

    $bottomContentBorder: solid 1px #bbbbbb;

    > .bottom-content {
        @include set-size(100%, $paymentTicketHeight - $topHeaderHeight);
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
        border-bottom: $bottomContentBorder;
        border-left: $bottomContentBorder;
        border-right: $bottomContentBorder;
        padding: 0 50px;
        position: relative;

        > .price {
            @include center-alignment();
            height: calc(100% - 40px - #{$verticalPadding});
            padding: 0 60px;

            > div {
                position: relative;
                top: 10px;
                width: 100%;

                & > .label {
                    @include set-font('ROBOTO', 12px, -0.1px); 
                    display: flex;
                    justify-content: flex-end;
                    color: #d0021b;

                    > span:nth-child(2) {
                        text-decoration: line-through;
                        margin-left: 10px;
                    }
                }

                & > .sum {
                    text-align: right;

                    > span:nth-child(1) {
                        @include set-font('ROBOTO', 50px, -3px, lighter); 
                        position: relative;
                        top: 1px;
                        right: 10px;
                    }
                    > span:nth-child(2) {
                        @include set-font('ROBOTO', 22px, -0.2px); 
                    }
                }
            }
        }

        > button {
            @include set-font('ROBOTO', 14px, -0.4px);
            width: calc(100% - #{$horizontalPadding} * 2) !important;
            position: absolute;
            bottom: $verticalPadding;
            left: $horizontalPadding;
        }
    }
}