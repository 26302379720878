@import './variable.scss';

// TODO 변수명 통일

@mixin set-font($font-family, $font-size, $letter-spacing, $font-weight: normal) {
    font-family: $font-family !important;
    font-size: $font-size !important;
    font-weight: $font-weight !important;
    letter-spacing: $letter-spacing !important;
}

// TODO 모든 부분에 적용
@mixin set-absolute($top: default, $bottom: default, $left: default, $right: default) {
    position: absolute;
    top: $top;
    bottom: $bottom;
    left: $left;
    right: $right;
}

@mixin set-relative($top: default, $bottom: default, $left: default, $right: default) {
    position: relative;
    top: $top;
    bottom: $bottom;
    left: $left;
    right: $right;
}

// TODO 모든 부분에 적용
@mixin set-size($width: default, $height: default) {
    width: $width;
    height: $height;
}

// TODO 모든 부분에 적용
@mixin button-action($hover: 0.9, $active: 0.7) {
    cursor: pointer;

    &:hover {
        opacity: $hover;
    }

    &:active {
        opacity: $active;
    }

    &.disabled {
        opacity: 0.3;
        pointer-events: none; 
    }
}

// TODO 모든 부분에 적용
@mixin center-alignment($display: flex) {
    display: $display !important;
    align-items: center !important;
    justify-content: center !important;
}

// TODO 모든 부분에 적용
@mixin set-content-size() {
    height: calc(100vh - #{$headerHeight});
    width: calc(100vw - #{$lefterWidth});
}

// TODO 모든 부분에 적용
@mixin set-ellipsis() {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin set-fade-in-out($duration) {
    animation-duration: 2s;
    animation-name: fadeInOut;      
    animation-iteration-count: infinite;
    color: #fff;

    @keyframes fadeInOut {
        0% {
            opacity: 1;
        }
      
        50% {
            opacity: 0.4;
        }

        100% {
            opacity: 1;
        }
    }
}

@function px($value) {
    @return $value * 1px;
}

@function vw($value) {
    @return $value * 1vw;
}
