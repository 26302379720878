@import '../../../../common-style/mixin.scss';
@import '../../../../common-style/variable.scss';

@mixin set-tab-color($color) {
    border-bottom: solid 2px $color;
    color: $color;    
}

@mixin set-project-create-half-panel() {
    @include set-size(50%, 100%);
    color: $themeBlue;
}

@mixin set-common-div-input($width, $height) {
    @include set-font('ROBOTO', 18px, -0.4px, normal);
    @include set-size($width, $height);                
    line-height: $height - 2px; // TODO 측정값
    border-radius: 2px;
}

@mixin set-common-top-span() {
    @include set-font('ROBOTO', 18px, -0.4px);
    color: $themeBlue;
}

@mixin set-template-people() {
    @include set-size(100%, 650px);
    background-color: #fff;
    border-radius: 2px;
}

$inputColor: #9b9b9b;
$inputColorLight: #9b9b9b7f;
$inputPadding: 8px;
$divInputHeight: 35px;
$divInputWidth: 450px;

.project-create {
    @include set-content-size();
    overflow: scroll;

    $contentsMargin: 40px;

    > .contents {
        @include set-size(1140px, 815px);
        position: relative;
        padding: 0 $contentsMargin;
        margin: $contentsMargin auto;

        $tabWidth: 120px;
        $tabMargin: 4px;

        > .top {
            > .name {
                padding-left: $inputPadding !important;

                &.loading {
                    opacity: 0;
                }
            }

            > input.name {
                @include set-common-div-input($tabWidth * 2 + $tabMargin, $divInputHeight);
                // @include set-common-div-input($divInputWidth, $divInputHeight);
                font-size: 24px !important;
                margin-bottom: 4px;
            }

            > div.name {
                // @include set-common-div-input(($tabWidth * 2 + $tabMargin) * 3 / 2, $divInputHeight);
                @include set-common-div-input($divInputWidth, $divInputHeight);
                @include set-ellipsis();
                font-size: 24px !important;
            }
            
            > .right {
                float: right;

                > span {
                    @include button-action();

                    > .url {
                        @include set-common-top-span();
                        text-decoration: underline;
                    }
        
                    > .query {
                        @include set-common-div-input($tabWidth, $divInputHeight);
                        margin-right: $contentsMargin;
                        text-decoration: underline;
                    }    
    
                    > div.query {
                        width: auto !important;
                        margin-right: 0;
                    }
                }

                > span.query-copy {
                    @include button-action();
                    margin: 0 40px 0 10px;
                    color: $themeBlue;
                    
                    $svgSize: 20px;

                    > svg {
                        @include set-size($svgSize, $svgSize);
                        position: relative;
                        top: 4px;
                    }
                }

                > .date {
                    @include set-common-div-input(100px, $divInputHeight); // TODO 측정 값

                    &.start {
                        padding-right: $inputPadding;
                        text-align: right;
                    }

                    &.end {
                        padding-left: $inputPadding;
                        text-align: left;
                    }
                }
                
                > .dash {
                    @include set-common-top-span();
                }
            }
        }

        > .tab {
            @include set-size(100%, 52px); // TODO 측정 값
            margin-top: 30px;

            > div {
                @include set-font('ROBOTO', 15px, -0.4px);
                @include center-alignment(inline-flex);
                @include set-size($tabWidth, 100%);
                @include set-tab-color($color: rgba(0,0,0,0.54));
                cursor: pointer;

                $svgSize: 19px;

                > svg {
                    @include set-size($svgSize, $svgSize);
                    position: relative;
                    margin-right: 8px;
                    bottom: 1px;
                }

                &.selected {
                    @include set-tab-color($color: $themeBlue);
                }

                &:not(:last-of-type) {
                    margin-right: $tabMargin;
                }
            }
        }

        > .buttons {
            @include set-size(100%, 55px);
            display: flex;
            justify-content: flex-end;
            padding-top: 15px;
            text-align: right;
            margin-bottom: $contentsMargin;

            &.loading {
                opacity: 0;
            }

            > button:not(:last-child) {
                margin-right: 10px;
            }        
        }    
    }
}
