@import '../../../../../../common-style/mixin.scss';
@import '../../../../../../common-style/variable.scss';
@import './index.scss';

.project-create-preview-page.one {
    &.broof {
        display: block !important;

        > .top {
            position: relative !important;
            top: unset !important;
            min-height: $topMinHeight;
        }

        > .bottom {
            position: relative !important;
            bottom: unset !important;
        }
    }

    overflow: scroll;

    input {
        -webkit-appearance: none;
        border-radius: 0;  
    }

    > .top {
        @include set-absolute($top: 0);
        @include set-size(100%, $topHeight);
        color: #fff;
        word-break: keep-all;       
        overflow: hidden;     
        
        $imgHeight: 20px;
        $bottomMargin: 20px;
        $pHeight: 40px;
                
        > img.back {
            @include set-absolute($top: 0, $bottom: 0, $left: 0, $right: 0);
        }

        > img.icon {
            @include set-absolute($top: $previewMargin, $left: $previewMargin);
            height: $imgHeight;
        }

        > p:first-of-type {
            @include set-font('ROBOTO', 23px, -0.4px, 500);
            @include set-absolute($top: $previewMargin + $imgHeight + 10px, $left: $previewMargin, $right: $previewMargin, $bottom: $bottomMargin + $pHeight + 25px);
        }

        > p:last-of-type {
            @include set-font('ROBOTO', 12px, -0.4px);
            @include set-absolute($bottom: $bottomMargin , $left: $previewMargin, $right: $previewMargin);
            line-height: 1.6;
            height: $pHeight;
        }
    }
    
    > .bottom {
        @include set-absolute($bottom: 0);
        @include set-size(100%, calc(100% - #{$topHeight}));
        @include set-font('ROBOTO', 12px, -0.4px);
        color: $inputColor;
        padding: 15px $previewMargin 0px $previewMargin;

        > div {
            @include set-font('ROBOTO', 12px, -0.4px);
            margin-top: $previewMargin;
            
            > input {
                @include set-font('ROBOTO', 16px, 0.2px);
                @include set-size(100%, 45px);
                border: none;
                border-bottom: solid 1px inherit;
                color: $inputColor;
            }

            > input::placeholder {
                color: $inputColorLight;   
            }
        }
        
        > button {
            margin: 45px 0;
        }

        // TODO
        // 사파리에서 마지막에 element 에 margin-bottom 안 먹는 이슈 존재
        > .blank {
            display: inline-block;
            margin-top: 0 !important;
        } 
    }
}