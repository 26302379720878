@import '../../common-style/common.scss';
@import '../../common-style/variable.scss';
@import '../../common-style/mixin.scss';

.find, .sign-up {
    @include set-font('ROBOTO', 12px, -0.3px);
    @include button-action();
    margin-top: 30px;
    text-decoration: underline;
    color: $themeBlue;
}