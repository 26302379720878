@import '../../common-style/variable.scss';
@import '../../common-style/mixin.scss';

$horizontalPadding: 30px;

.lefter {
    position: relative;
    width: $lefterWidth;
    height: calc(100vh - #{$headerHeight});
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #323e52;  
    color: #fff;
    
    > *:not(:first-child) {
        border-bottom: solid 1px rgba(255,255,255,0.1);      
    }

    > .title {
        padding: ($horizontalPadding + 1px) $horizontalPadding 0px $horizontalPadding;

        > .hi {
            // TODO 삼항 연산자 쓸 수 있을까?
            @include set-font('ROBOTO', 16px, -0.5px);
            margin-bottom: 2px;       
        }
        
        > .user {
            @include set-font('ROBOTO', 20px, -0.5px);
        }

        $buttonHeight: 50px;

        > .new-button {
            padding-right: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: $buttonHeight;
            border-radius: $buttonHeight;
            background-color: #ffffff;
            margin: 12px 0 17px 0; 
            color: $themeBlue;
            cursor: pointer;

            &:hover {
                opacity: 0.9;
            }
            
            &:active {
                opacity: 0.7;
            }
        
            $svgSize: 25px;

            > svg {
                width: $svgSize;
                height: $svgSize;
            }  
            
            > span {
                position: relative;
                top: 1px;
            }
        }
    }

    $couponVerticalPadding: 20px;

    > .coupon {
        background-color: rgba(0, 0, 0, 0.25);
        height: 110px;
        padding: $couponVerticalPadding $horizontalPadding $couponVerticalPadding $horizontalPadding;

        > .top {
            display: flex;
            justify-content: space-between;
            // TODO 왜 얘가 안먹지?
            // align-items: center;
            
            > span:first-child {
                @include set-font('ROBOTO', 16px, -0.5px);
            }
            > span:last-child {
                @include set-font('ROBOTO', 12px, -0.3px);
                @include button-action();
                
                > svg {
                    @include set-relative($top: 2px);
                }
            }
        }

        $bottomLastTop: 4px;

        > .bottom {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            
            > span:first-child {
                @include set-font('ROBOTO', 40px, -0.3px, lighter);
                @include set-relative($top: 6px + $bottomLastTop);
                padding: 0 10px 0 0;
            }
            > span:last-child {
                @include set-font('ROBOTO', 16px, -0.5px);
                @include set-relative($top: $bottomLastTop);
            }
        }
    }

    > .menu {
        @include set-font('ROBOTO', 16px, -0.5px);

        height: 55px;
        width: $lefterWidth;
        display: flex;    
        align-items: center;
        padding: 0 calc(#{$horizontalPadding} - 5px) 0 $horizontalPadding;

        &:hover {
            cursor: pointer;
            > * {
                opacity: 0.6;
            }    
        }

        // TODO 조건문으로 처리 가능?
        > * {
            opacity: 0.4;
        }
        
        &.selected > * {
            opacity: 1;
        }

        > span {
            width: 167px; // 측정값
            margin-left: $horizontalPadding;            
        }

        $svgSize: 24px;

        > svg {
            width: $svgSize;
            height: $svgSize;
        }

        // TODO 마이페이지 아이콘이 4px 정도 큼
        &:last-of-type > svg {
            padding: 2px;
        }
    }

    .logout {
        @include set-absolute($bottom: 0);
        @include set-size(100%, 55px);
        @include set-font('ROBOTO', 16px, -0.4px);
        @include center-alignment();
        cursor: pointer;

        background-color: rgba(0, 0, 0, 0.25);
        height: 55px;
        color: #fff;

        &:hover {
            > * {
                opacity: 0.4;
            }
        }

        &:active {
            > * {
                opacity: 0.3;
            }
        }

        > * {
            opacity: 0.6;
        }

        > img {
            margin-right: 10px;
        }

        > span {
            position: relative;
            top: 1px;
        }
    }
}