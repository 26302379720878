@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');

@font-face {
    font-family: 'AndaleMono';
    src: url('./fonts/AndaleMono.ttf') format('truetype')
}

@font-face {
    font-family: 'HumanMyeongjo';
    src: url('./fonts/HumanMyeongjo.ttf') format('truetype')
}

@font-face {
    font-family: 'TimesNewRoman';
    src: url('./fonts/TimesNewRoman.ttf') format('truetype')
}

body {
    margin: 0;
    padding: 0;
}

* {
    font-family: 'ROBOTO';
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.has-popup {
    overflow: hidden;
    height: 100vh;
}

a, a:hover, a:active {
    color: inherit;
    text-decoration: none;
}

button {
    border:0 none;
    background-color:transparent;
    cursor:pointer;
    color: inherit;
}

input:focus, select:focus, textarea:focus, button:focus {
    outline: none;
}

p, h1, h2 {
    padding: 0 !important;
    margin: 0 !important;
}

h1, h2 {
    font-weight: normal;
    font-size: inherit;
}

table {
    border-spacing: 0 !important;
}

th {
    font-weight: normal;
}
