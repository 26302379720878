@import '../../common-style/mixin.scss';
@import '../../common-style/variable.scss';


// TODO 효율적으로 수정
// TODO 클래스에 변수명 넣을 수 있을까?

._parts-button {
    @include set-font('Roboto', 14px, -0.4px);
    @include button-action();
    display: inline-block;
    width: 100px;
    text-align: center;
    padding: 10px;
    border-radius: 100px;
}