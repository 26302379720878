@charset "UTF-8";
.project-create {
  height: calc(100vh - 65px);
  width: calc(100vw - 300px);
  overflow: scroll; }
  .project-create > .contents {
    width: 1140px;
    height: 815px;
    position: relative;
    padding: 0 40px;
    margin: 40px auto; }
    .project-create > .contents > .top > .name {
      padding-left: 8px !important; }
      .project-create > .contents > .top > .name.loading {
        opacity: 0; }
    .project-create > .contents > .top > input.name {
      font-family: "ROBOTO" !important;
      font-size: 18px !important;
      font-weight: normal !important;
      letter-spacing: -0.4px !important;
      width: 244px;
      height: 35px;
      line-height: 33px;
      border-radius: 2px;
      font-size: 24px !important;
      margin-bottom: 4px; }
    .project-create > .contents > .top > div.name {
      font-family: "ROBOTO" !important;
      font-size: 18px !important;
      font-weight: normal !important;
      letter-spacing: -0.4px !important;
      width: 450px;
      height: 35px;
      line-height: 33px;
      border-radius: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 24px !important; }
    .project-create > .contents > .top > .right {
      float: right; }
      .project-create > .contents > .top > .right > span {
        cursor: pointer; }
        .project-create > .contents > .top > .right > span:hover {
          opacity: 0.9; }
        .project-create > .contents > .top > .right > span:active {
          opacity: 0.7; }
        .project-create > .contents > .top > .right > span.disabled {
          opacity: 0.3;
          pointer-events: none; }
        .project-create > .contents > .top > .right > span > .url {
          font-family: "ROBOTO" !important;
          font-size: 18px !important;
          font-weight: normal !important;
          letter-spacing: -0.4px !important;
          color: #0008ff;
          text-decoration: underline; }
        .project-create > .contents > .top > .right > span > .query {
          font-family: "ROBOTO" !important;
          font-size: 18px !important;
          font-weight: normal !important;
          letter-spacing: -0.4px !important;
          width: 120px;
          height: 35px;
          line-height: 33px;
          border-radius: 2px;
          margin-right: 40px;
          text-decoration: underline; }
        .project-create > .contents > .top > .right > span > div.query {
          width: auto !important;
          margin-right: 0; }
      .project-create > .contents > .top > .right > span.query-copy {
        cursor: pointer;
        margin: 0 40px 0 10px;
        color: #0008ff; }
        .project-create > .contents > .top > .right > span.query-copy:hover {
          opacity: 0.9; }
        .project-create > .contents > .top > .right > span.query-copy:active {
          opacity: 0.7; }
        .project-create > .contents > .top > .right > span.query-copy.disabled {
          opacity: 0.3;
          pointer-events: none; }
        .project-create > .contents > .top > .right > span.query-copy > svg {
          width: 20px;
          height: 20px;
          position: relative;
          top: 4px; }
      .project-create > .contents > .top > .right > .date {
        font-family: "ROBOTO" !important;
        font-size: 18px !important;
        font-weight: normal !important;
        letter-spacing: -0.4px !important;
        width: 100px;
        height: 35px;
        line-height: 33px;
        border-radius: 2px; }
        .project-create > .contents > .top > .right > .date.start {
          padding-right: 8px;
          text-align: right; }
        .project-create > .contents > .top > .right > .date.end {
          padding-left: 8px;
          text-align: left; }
      .project-create > .contents > .top > .right > .dash {
        font-family: "ROBOTO" !important;
        font-size: 18px !important;
        font-weight: normal !important;
        letter-spacing: -0.4px !important;
        color: #0008ff; }
    .project-create > .contents > .tab {
      width: 100%;
      height: 52px;
      margin-top: 30px; }
      .project-create > .contents > .tab > div {
        font-family: "ROBOTO" !important;
        font-size: 15px !important;
        font-weight: normal !important;
        letter-spacing: -0.4px !important;
        display: inline-flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: 120px;
        height: 100%;
        border-bottom: solid 2px rgba(0, 0, 0, 0.54);
        color: rgba(0, 0, 0, 0.54);
        cursor: pointer; }
        .project-create > .contents > .tab > div > svg {
          width: 19px;
          height: 19px;
          position: relative;
          margin-right: 8px;
          bottom: 1px; }
        .project-create > .contents > .tab > div.selected {
          border-bottom: solid 2px #0008ff;
          color: #0008ff; }
        .project-create > .contents > .tab > div:not(:last-of-type) {
          margin-right: 4px; }
    .project-create > .contents > .buttons {
      width: 100%;
      height: 55px;
      display: flex;
      justify-content: flex-end;
      padding-top: 15px;
      text-align: right;
      margin-bottom: 40px; }
      .project-create > .contents > .buttons.loading {
        opacity: 0; }
      .project-create > .contents > .buttons > button:not(:last-child) {
        margin-right: 10px; }

.recipient-info, .certificate-container .printed {
  position: absolute;
  top: 0;
  bottom: default;
  left: 0;
  right: default;
  font-family: 'ROBOBO';
  z-index: 0;
  color: #000;
  border-radius: 3px;
  text-align: center; }

.project-create-preview {
  width: 50%;
  height: 100%;
  color: #0008ff; }
  .project-create-preview > .one, .project-create-preview .two, .project-create-preview .three {
    display: block;
    position: relative;
    border: solid 1px #e1e1e1;
    width: calc(100% - 20px * 4 - 20px * 2);
    margin: 0 20px; }
    .project-create-preview > .one::-webkit-scrollbar, .project-create-preview .two::-webkit-scrollbar, .project-create-preview .three::-webkit-scrollbar {
      display: none; }
  .project-create-preview.single {
    width: 100%; }
    .project-create-preview.single > .one, .project-create-preview.single .two, .project-create-preview.single .three {
      display: block;
      position: relative;
      border: none;
      width: 100%;
      margin: 0; }
  .project-create-preview > div {
    width: 20px;
    height: 100%;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    vertical-align: middle; }
    .project-create-preview > div.move > div {
      width: 20px;
      height: 20px;
      cursor: pointer; }
      .project-create-preview > div.move > div:hover {
        opacity: 0.9; }
      .project-create-preview > div.move > div:active {
        opacity: 0.7; }
      .project-create-preview > div.move > div.disabled {
        opacity: 0.3;
        pointer-events: none; }
      .project-create-preview > div.move > div > svg {
        width: 20px;
        height: 20px; }
    .project-create-preview > div.disabled > div {
      cursor: default;
      color: rgba(0, 0, 0, 0.24); }
    .project-create-preview > div.dot > div {
      width: 20px;
      height: 20px;
      cursor: pointer;
      display: inline-flex !important;
      align-items: center !important;
      justify-content: center !important; }
      .project-create-preview > div.dot > div:hover {
        opacity: 0.9; }
      .project-create-preview > div.dot > div:active {
        opacity: 0.7; }
      .project-create-preview > div.dot > div.disabled {
        opacity: 0.3;
        pointer-events: none; }
      .project-create-preview > div.dot > div > svg {
        width: 12px;
        height: 12px; }

.certificate-container {
  position: absolute;
  top: 0;
  bottom: default;
  left: 0;
  right: default;
  width: 100%; }
  .certificate-container .printed {
    width: 27.17391vw !important;
    margin: 1.3587vw !important;
    border: none;
    pointer-events: none;
    padding: 0 !important; }
    .certificate-container .printed.name {
      font-size: 4.34783vw !important;
      /* for 문 이용 */ }
      .certificate-container .printed.name.size-20 {
        font-size: 5.43478vw !important; }
      .certificate-container .printed.name.size-18 {
        font-size: 4.8913vw !important; }
      .certificate-container .printed.name.size-16 {
        font-size: 4.34783vw !important; }
      .certificate-container .printed.name.size-14 {
        font-size: 3.80435vw !important; }
      .certificate-container .printed.name.size-12 {
        font-size: 3.26087vw !important; }
    .certificate-container .printed.extra {
      font-size: 2.71739vw !important;
      transform: scale(0.8); }
      .certificate-container .printed.extra > p:nth-child(1) {
        width: 90vw !important;
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: keep-all;
        line-height: 1.3; }
    .certificate-container .printed > p:nth-child(1) {
      top: 2.98913vw !important;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      white-space: nowrap;
      position: absolute; }
  .certificate-container > img {
    width: 100%;
    height: default; }
  .certificate-container.postech-golf {
    color: #000 !important; }
    .certificate-container.postech-golf > * {
      width: 100%;
      height: default;
      font-family: 'Noto Sans', sans-serif !important;
      text-align: center;
      z-index: 0; }
    .certificate-container.postech-golf > .name {
      position: absolute;
      top: 38%;
      bottom: default;
      left: default;
      right: default;
      font-size: 8.1vw; }
    .certificate-container.postech-golf > .title {
      position: absolute;
      top: 55%;
      bottom: default;
      left: default;
      right: default;
      transform: scale(0.8);
      font-size: 3vw; }
    .certificate-container.postech-golf > .date {
      position: absolute;
      top: 78%;
      bottom: default;
      left: default;
      right: default;
      transform: scale(0.6);
      font-size: 3vw; }
  .certificate-container.bcapitalist-2nd {
    color: #000 !important; }
    .certificate-container.bcapitalist-2nd > * {
      width: 100%;
      height: default;
      font-family: sans-serif !important;
      font-weight: bold;
      text-align: left;
      z-index: 0;
      font-size: 2.6vw; }
    .certificate-container.bcapitalist-2nd > .name {
      position: absolute;
      top: 27.9%;
      bottom: default;
      left: 22.7%;
      right: default; }
    .certificate-container.bcapitalist-2nd > .company {
      position: absolute;
      top: 31.6%;
      bottom: default;
      left: 22.7%;
      right: default; }
  .certificate-container.bcapitalist {
    color: #000 !important; }
    .certificate-container.bcapitalist > * {
      width: 100%;
      height: default;
      font-family: sans-serif !important;
      font-weight: bold;
      text-align: left;
      z-index: 0;
      font-size: 2.6vw; }
    .certificate-container.bcapitalist > .name {
      position: absolute;
      top: 20.7%;
      bottom: default;
      left: 19.5%;
      right: default; }
    .certificate-container.bcapitalist > .company {
      position: absolute;
      top: 24.4%;
      bottom: default;
      left: 19.5%;
      right: default; }
  .certificate-container.founders-2019-summer {
    color: #000 !important; }
    .certificate-container.founders-2019-summer > .name {
      position: absolute;
      top: 40%;
      bottom: default;
      left: 1vw;
      right: default;
      font-family: 'HumanMyeongjo' !important;
      font-size: 7.5vw;
      width: 100%;
      letter-spacing: 2vw;
      text-align: center; }
  .certificate-container.posahs {
    color: #000 !important; }
    .certificate-container.posahs > div {
      font-family: 'HumanMyeongjo' !important;
      width: 50%;
      text-align: center;
      position: absolute; }
    .certificate-container.posahs > .name {
      top: 34%;
      left: calc(37.5% + 2vw / 2);
      font-size: 8vw;
      letter-spacing: 2vw; }
    .certificate-container.posahs > .major {
      top: 49%;
      left: 37.5%;
      font-size: 1.9vw; }
