@import '../../../../../common-style/mixin.scss';
@import '../../../../../common-style/variable.scss';
@import '../index.scss';

@mixin set-svg($color, $svgSize, $marginRight) {
    color: $color;
    @include set-size($svgSize, $svgSize);
    margin-right: $marginRight !important;                
}

$topMargin: 10px;
$bottomMargin: 30px;
$sideMargin: 30px;
$topHeight: 40px;
$bottomHeight: 15px;
$lineColor: rgba(0, 0, 0, 0.1);   

.project-create-people {
    @include set-template-people(); // TODO 이거 수정 해야 겠다
    display: block;
    padding: $sideMargin;
    padding-top: $topMargin;
    padding-bottom: $bottomMargin;

    &.loading {
        display: inline-flex;
    }
    
    > .top {
        @include set-size(100%, 40px);
        margin-bottom: $topMargin;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        $svgSize: 20px;

        > .search {
            display: inline-flex;
            align-items: center;    

            $inputHeight: 28px;
            $searchMargin: 8px;

            > svg {
                @include set-svg($themeBlue, $svgSize, $searchMargin)
            }

            // TODO Create.scss 에서 복붙한 뒤 수정
            > input {
                color: #9b9b9b;
                border: solid 0.5px $lineColor;
                @include set-font('ROBOTO', 14px, -0.4px, normal);
                @include set-size(244px, $inputHeight);                
                border-radius: 2px;
                padding: 1px 0 0 $searchMargin;
            }

            > input::placeholder {
                color: #9b9b9b7f;
            }
        }

        > .buttons {
            display: inline-flex;
            align-items: center;    

            $buttonsMargin: 12px;

            > a > svg {
                margin-top:4px;
                @include set-svg($themeBlue, $svgSize, $buttonsMargin);
            }
            // TODO 정리했으면
            > div,
            > span > a, 
            > span > .react-file-reader > div {
                @include center-alignment();
                @include button-action();
                cursor: pointer;
                
                > svg {
                    @include set-svg($themeBlue, $svgSize, $buttonsMargin);
                }
                            
            }
            > div.background{
                position: relative;
                // &::before{
                //     content:'';
                //     display:block;
                //     top:-6.5px;
                //     left:-8px;
                //     width:32px;
                //     height:32px;
                //     position: absolute;
                //     border-radius: 50%;
                //     opacity:0.08;
                //     background:$themeBlue;                   
                // }
            }

            > span {
                @include button-action();
            }

            > div:last-of-type {
                > svg {
                    color: $tableSvgColor;
                    margin-right: 0 !important;    
                }
            }
            
            > .division {
                @include set-size(1px, $svgSize);
                display: inline-block;
                background-color: $lineColor;
                margin-left: $buttonsMargin / 2;
                margin-right: $buttonsMargin * 1.5;
                margin-top:22.5px;
            }
            
            .list-svg{
                @include set-svg($themeBlue, $svgSize, $buttonsMargin);
            }
        }
    }   

    // TODO 이것 좀 깔끔히 안될까?
    $tableBoxWidth: 100%;
    $tableBoxHeight: calc(100% - #{$topHeight} -  #{$topMargin} - #{$topMargin} - #{$bottomHeight});
    
    > .table-box {
        @include set-size($tableBoxWidth, $tableBoxHeight);
        border-bottom: solid 1px $lineColor;

        // TODO 보류
        // &.added {
        //     > .body-wrapper > table > tbody > tr:nth-of-type(2n) > td {
        //         background-color: #fff;
        //     }

        //     > .body-wrapper > table > tbody > tr:nth-of-type(2n - 1) > td {
        //         background-color: rgba(0, 8, 255, 0.05);
        //     }
        // }
    }

    > .bottom {
        @include set-size(100%, $bottomHeight);
        margin-top: $topMargin;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        color: $themeBlue;

        > .count {
            @include set-font('ROBOTO', 14px, -0.4px, normal);
        }

        > .causion {
            @include set-font('ROBOTO', 12px, -0.4px, normal);
        }
    }
}
