@import '../../../common-style/mixin.scss';
@import '../../../common-style/variable.scss';

$padding: 36px;

.containers-popup {
    @include set-absolute($top: 0, $left: 0);
    @include set-size(100%, 100%);
    @include center-alignment();
    background-color: rgba(50, 62, 82, 0.5);

    > .contents {
        position: relative;
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);

        &.default {
            @include set-size($width: 400px);
            background-color: #fafafa;      
            padding: $padding;    
        }

        $svgSize: 25px;

        > .close {
            @include button-action();
            @include set-absolute($right: $padding, $top: $padding);
            @include set-size($svgSize, $svgSize);
            z-index: 1;
            color: #bbbbbb;

            > svg {
                @include set-size($svgSize, $svgSize);
            }
        }

        > div {
            > .title {
                @include set-font('ROBOTO', 24px, -0.5px);
                color: $themeBlue;
                margin-bottom: $padding;
            }
    
            > .description {
                @include set-font('ROBOTO', 16px, -0.4px);
                @include set-ellipsis();
                line-height: 1.5;
                color: #323e52;
                margin-bottom: $padding;
    
                > b {
                    color: $themeBlue;
                }
            }
        }       
    }
}